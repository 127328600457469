

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen  and (min-width : 320px) and (max-width : 480px) {

  .product_slider1 {
    h1{
      font-size:3rem;
    }
  }

  .recap{
    width:100%;
  }
.slides2 {
  min-height: 300px;
}
.slider_product{
  max-height:230px
}
  .breadcrumb2 {
    a:after{
      right: -14px;
    }
    a:last-child{
      padding-right:0px;
    }
  }
  .flat {
    a{
      width: 33px;
    }
  }
  .flat {
    a:after{
      width: 33px;
    }
  }
  .product_slider1 {
    h1 {
      margin-top: 10px;
    }
  }
  .product_slider1 {
    .price {
      font-size: 3rem;
      background-size: 60px auto;
      background-position: 226px -2px;
    }

    .btn_pack{
      width:100%;
    }
  }

  footer{
    display:none;
  }

  .service_client{
    display:none;
  }
  .navbar_cart, .navbar_compte_margin_right{
    margin-right:0px;
  }
  .nav_2 > li {
    width: 31%;
    a{
      font-size:10px;
    }
  }
  .nav_2 > li:nth-child(-n+3) {
    border-bottom: 1px solid #9a9a9a;
  }

  .container_slider  {

    .bg_slide1{
      min-height:145px;
    }
    .slide1 {
      position: absolute;
      top: 30%;
      left: 66%;
      font-size: 1rem;
      width: 100px;

      .tentez_experience {
        border: 3px solid #FFFFFF;
        padding: 3px;
        color: #FFF;
        font-size: 1rem;
        margin-top: 8px;
        width: 100%;
        margin-left: 0px;
      }
    }

    .slide2 {
      width: 100%;
      left: -25%;
      top: 6%;
      position: absolute;
      font-size: 1rem;
    }

    .slide3 {
      top: 0%;
      left: -21%;
      .sec1 {
        font-size: 1rem;
      }

      .sec2 {
        font-size: 1rem;
      }
      .sec3 {
        font-size: 1rem;
      }
      .sec4 {
        font-size: 1rem;
      }

      .product_slider{
        width:50%;
      }
    }

    .slide4 {
      top: 14%;
      left: -32%;
      .sec1 {
        font-size: 1rem;
      }
      .sec2 {
        font-size: 1rem;
      }
      .sec3 {
        font-size: 1rem;
      }
      .sec4 {
        font-size: 1rem;
      }
    }


    .slide5 {
      top:16%;
      left:0%;
      .bloc5{
        width:80%;
        margin:0 auto;
      }
      .sec1 {
        font-size: 1rem;
      }

      .sec2 {
        font-size: 1rem;
      }
      .sec3 {
        font-size: 1rem;
      }
      .sec4 {
        font-size: 1rem;
      }

      .product_slider{
        width:50%;
      }
    }


  }


  .down{
    display: none;
  }

  .osez  {
    font-size: 1rem;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;

    h3 {
      font-size: 2rem;
      margin-bottom:0px;
    }
    .icons {
      margin-left: 32%;
      margin-top: 9px;
    }
    .div_icon {
      width: 28px;
      float: left;
    }

  }

  .esprit_text{
    border-left:0px;
  }

  .esprit_image{
    display: none;
  }

}

/* Small Devices, Tablets */
@media only screen and (min-width : 480px)  and (max-width : 768px) {
  .slides2 {
    min-height: 300px;
  }

  .product_slider1 {
    h1{
      font-size:3rem;
    }
  }

  .slider_product{
    max-height:230px
  }

  .recap{
    width:100%;
  }
  .breadcrumb2 {
    a:after{
      right: -14px;
    }
    a:last-child{
      padding-right:0px;
    }
  }
  .flat {
      a{
        width: 33px;
      }
  }
  .flat {
    a:after{
      width: 33px;
    }
  }
  .product_slider1 {
    h1 {
      margin-top: 10px;
    }

    .price {
      font-size: 3rem;
      background-size: 60px auto;
      background-position: 226px -2px;
    }

    .btn_pack{
      width:100%;
    }
  }
  .service_client{
    display:none;
  }


  .esprit_text{
    border-left:0px;
  }

  .esprit_image{
    display: none;
  }



  .nav_2{
    li{
      width: 100%;
      float: left;
      text-align: center;
      height: 32px;
    }
  }

  footer{
    display:none;
  }

  .container_slider  {
    .slide1 {
      position: absolute;
      top: 38%;
      left: 62%;
      font-size: 2rem;
      width: 157px;


      .tentez_experience {
        border: 3px solid #FFFFFF;
        padding: 3px;
        color: #FFF;
        font-size: 2rem;
        margin-top: 8px;
        width: 241px;
        margin-left: 0px;
      }
    }

    .slide2 {
      width: 100%;
      left: -25%;
      top: 6%;
      position: absolute;
      font-size: 1rem;
    }

    .slide3 {
      top: 0%;
      left: -21%;
      .sec1 {
        font-size: 3rem;
      }

      .sec2 {
        font-size: 2rem;
      }
      .sec3 {
        font-size: 3rem;
      }
      .sec4 {
        font-size: 2rem;
      }

      .product_slider{
        width:50%;
      }
    }

    .slide4 {
      top: 14%;
      left: -32%;
      .sec1 {
        font-size: 3rem;
      }
      .sec2 {
        font-size: 3rem;
      }
      .sec3 {
        font-size: 2rem;
      }
      .sec4 {
        font-size: 1rem;
      }
    }


  }


  .down{
    display: none;
  }

  .osez  {
    font-size: 2rem;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;

    h3 {
      font-size: 3rem;
    }
    .icons {
      margin-left: 38%;
      margin-top: 9px;
    }
    .div_icon {
      width: 57px;
      float: left;
    }

  }

  .corps_home {
    .in_home{
      width: 100%;
      text-align: center;
    }

    .suivez_nous {
      background: url("/img/fond_suivez_nous.png");
      background-repeat: no-repeat;
      height: 180px;
      width: 100%;
      background-size: 100% auto;
    }
  }


}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}
