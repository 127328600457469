.mon_compte{
  h1{
    color:#FFF;
  }
  div{
    background : #B11616;
    border:1px solid #290C0C;
    padding:10px;
    color:#FFF;
    font-size:2rem;

    a{
      color:#FFF;
    }
    a:hover{
      text-decoration:none;
    }
    .fa{
      font-size:9rem;
    }

  }

}

.infos{

  .col-sm-4 {
    margin-top:10px;
    padding:10px;
    border: 1px solid #B11616;
  }
  input, textarea{
    background:#000;
    border:0px;
    border-bottom:1px solid $border_panier;
    color:#FFF;
  }

  input:focus{
    outline:0px;
  }

  label{
    float: left;
    width: 80%;
    font-weight: normal;
    color: #FFF;
  }
}