
 @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

 @import "node_modules/hover.css/scss/hover.scss";

 @import "fontawesome/font-awesome.scss";


 @import "bower_components/uikit/scss/uikit-mixins.scss";

 // your custom code goes here, e.g. mixins, variables

 @import "bower_components/uikit/scss/uikit.scss";
 @import "bower_components/uikit/scss/components/autocomplete.scss";

 @import url("https://fonts.googleapis.com/css?family=Oswald:400,300,700");

 $a_font_color: #777;
 $a_menu_hover_color: #FFF;
 $a_menu_hover_bg: #B11616;

 @import "common.scss";
 @import "slider.scss";
 @import "home.scss";
 @import "modal.scss";
 @import "partners.scss";
 @import "assistance.scss";
 @import "slider.scss";
 @import "esprit.scss";
 @import "produit.scss";
 @import "panier.scss";
 @import "compte.scss";
 @import "jquery.countdown.scss";
 @import "bootstrap-image-gallery.min.scss";
 @import "bootstrap-image-gallery.scss";
 @import "lp/crossfit.scss";
 @import "vegas.min.scss";


 @import "mobile/common.scss";


 .slides{
   min-height:700px;
 }
 #colophon {
   bottom: 0;
   height: 100px;
   left: 0;
   position: absolute;
   right: 0;
   background:red;
 }

 .space{
   margin-top:100px ;
 }

 .jarallax {
   position: relative;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: 50% 50%;
 }


 .jarallax {
   height: 500px;
 }

 .jarallax h1{
   color:#FFF;
 }


 /* demo menu */
 .demo-menu {
   background-color: #272333;
 }
 .demo-menu ul {
   list-style-type: none;
   text-align: center;
   margin: 0;
 }
 .demo-menu ul li {
   display: inline-block;
 }
 .demo-menu ul li a {
   display: block;
   padding: 20px 30px;
   text-decoration: none;
 }
 .demo-menu ul li.active a,
 .demo-menu ul li a:hover {
   background-color: #383344;
 }

 /* hero block */
 .demo-table {
   display: table;
   width: 100%;
   height: 500px;
   background-color: rgba(39,35,51,0.2);
 }
 .demo-table-cell {
   display: table-cell;
   text-align: center;
   vertical-align: middle;
 }

 /* gap */
 .demo-gap {
   padding: 50px;
   background-color: #272333;
   text-align: center;
 }


 .entre_deux{
  padding:20px;
   min-height:100px;
   color:#FFF;
 }


 .recap_commande{
   td{
     padding:10px;

   }
   .pdf{
     font-size:3rem;
   }
 }

 .service_client{
   color:#FFFFFF;
   padding-top:15px;
 }

 .comment_star{
   color:#FFFFFF;
 }
 .star{
   .fa-star, .fa-star-o{
     color: #cab20f;
   }
 }