.crossfit {
  background: #000000;
  min-height:1500px;
  .bg {
    background:url('/img/lp/bg2.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    min-height:1164px;
    background-position: center;

    .btn_acheter{
      margin-top:53%;
    }
    .btn_pack{
      width: 81%;
      float: left;


    }


  }

  .btn_100{
    .btn_pack{
      width:100%;


    }
  }
  .links{
    img{
      max-height:100px !important;
    }
  }

}

.crossfit2{
  background-color:#000000;
  color:#FFFFFF;
.sec_black{
  margin-bottom: 50px;
  padding-top:30px;
}

  .first_div{
    font-size: 2rem;
    line-height: 3rem;
  }
  .div_icon{
    float:left;
    width:14%;
  }
  .sec3 {
    font-size: 3rem;
    width: 100px;
    paddgin: 5px;
    background: #ae121c;
    color: #000000;
    font-weight: bold;
    float: left;
  }

  .btn_100{
    .btn_pack{
      width:100%;
      background:#009BD6;

      .add_to_cart{
        background:#009BD6;
      }


    }
  }
  .links{
    img{
      max-height:100px !important;
    }
  }

  .section_caract{
    background:#FFFFFF;
    width:100%;
  }
  .lp_accordion{
    background:#FFFFFF;
  }

  .partenaire{
    .block{
      position: absolute;
      margin-top: -200px;
      margin-left: 387px;
      font-size: 1.6rem;

      .sign{
        font-size:0.8rem;
      }
    }
  }

  .footer{
    text-align:center;
    width: 100%;
    background: #000000;

    .circle{
      color:#009BD6;
    }
  }

}