.first_assistance{
  margin-top:100px;
}

.assistance_bloc{
  color:#FFF;
  margin-bottom:20px;
}

.pdf
{
  min-height:100%;
}

.pdf object{
  width:100%;
  height:800px;
}