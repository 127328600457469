.esprit_main{
  color:#656565;

  img{
    max-width:100%;
  }
}

.esprit_text{


  border-width: 1px;
  border-style: solid;
  -webkit-border-image:
  -webkit-gradient(linear, 0 0, 0 100%, from(#656565), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image:
  -webkit-linear-gradient(#656565, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image:
  -moz-linear-gradient(#656565, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image:
  -o-linear-gradient(#656565, rgba(0, 0, 0, 0)) 1 100%;
  border-image:
  linear-gradient(to bottom, #656565, rgba(0, 0, 0, 0)) 1 100%;

  border-right:0px;
}