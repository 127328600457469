.container-fluid{
  padding:0px;
}
.slides2 {
  min-height: 700px;
}
.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  max-height:500px;

  .bg_slide1{
   /* background:url('/img/slider/TEETAN_slide3.png');*/
    background-repeat:no-repeat;
    background-size:100% 100%;
    min-height:500px;
  }
}

.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;


}
.overlay{
  background: url("/img/hash_slider.svg") 0 0 repeat;
  height:100%;
  width:100%;
  position:absolute;
  z-index:10;
  top:0;
}



.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
}

.rslides img {
  display: block;
  height: auto;
  float: none;

  border: 0;
  margin: 0 auto;
}



#wrapper {
  float: left;
  width: 100%;
  margin-bottom: 50px;
}



.rslides {
  margin: 0 auto;
}

.rslides_container {
  margin-bottom: 50px;
  position: relative;
  float: left;
  width: 100%;
}

.centered-btns_nav {
  z-index: 11;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  top: 50%;
  left: 0;
  opacity: 0.7;
  text-indent: -9999px;
  overflow: hidden;
  text-decoration: none;
  height: 73px;
  width: 38px;
 /* background: transparent url("/img/bouton_navigation_droit.png") no-repeat left top;*/
  margin-top: -45px;

  &.prev{
    background: transparent url("/img/icon-previous.svg") no-repeat left top;
  }

  &.next{
    background: transparent url("/img/bouton_navigation_droit.png") no-repeat left top;
  }
}



.centered-btns_nav:active {
  opacity: 1.0;
}

.centered-btns_nav.next {
  left: auto;
  background-position: right top;
  right: 0;
}

.transparent-btns_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  top: 0;
  left: 0;
  display: block;
  background: #fff; /* Fix for IE6-9 */
  opacity: 0;
  filter: alpha(opacity=1);
  width: 48%;
  text-indent: -9999px;
  overflow: hidden;
  height: 91%;
}

.transparent-btns_nav.next {
  left: auto;
  right: 0;
}

.large-btns_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  opacity: 0.6;
  text-indent: -9999px;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  background: #000 url("themes.gif") no-repeat left 50%;
  width: 38px;
}

.large-btns_nav:active {
  opacity: 1.0;
}

.large-btns_nav.next {
  left: auto;
  background-position: right 50%;
  right: 0;
}

.centered-btns_nav:focus,
.transparent-btns_nav:focus,
.large-btns_nav:focus {
  outline: none;
}

.centered-btns_tabs,
.transparent-btns_tabs,
.large-btns_tabs {
  margin-top: -20px;
  text-align: center;
  z-index: 99999;
  position: absolute;
  left: 45%;
}

.centered-btns_tabs li,
.transparent-btns_tabs li,
.large-btns_tabs li {
  display: inline;
  float: none;
  _float: left;
  *float: left;
  margin-right: 5px;
}

.centered-btns_tabs a,
.transparent-btns_tabs a,
.large-btns_tabs a {
  text-indent: -9999px;
  overflow: hidden;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background: #ccc !important;
  display: inline-block;
  _display: block;
  *display: block;
  -webkit-box-shadow: inset 0 0 2px 0 rgba(0,0,0,.3);
  -moz-box-shadow: inset 0 0 2px 0 rgba(0,0,0,.3);
  box-shadow: inset 0 0 2px 0 rgba(0,0,0,.3);
  width: 9px;
  height: 9px;
}

.centered-btns_here a,
.transparent-btns_here a,
.large-btns_here a {
  background: red !important;
}

.tentez_experience{
  border:3px solid #FFFFFF;
  padding:3px;
  color:#FFF;
  font-size:3rem;
  margin-top: 75px;
  width: 100%;
  margin-left: 0px;
}


 .previous {
  left: 10px;
  right: auto;
  background-image: url("/img/icon-previous.svg") !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

 .next {
  left: auto;
  right: 10px;
  background-image: url("/img/icon-next.svg") !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
 .next,  .previous {
  opacity: .8;
  visibility: visible;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background: center center no-repeat;
  background-size: cover;
  top: 50%;
}

//.rslides {
  .slide1 {
    position: absolute;
    top: 46%;
    left: 72%;
    z-index: 100;
  }
  .slide2{
    width:100%;
    left: -38%;
    top: 18%;
    position: absolute;
    z-index: 100;
  }

  .slide3{
    width: 100%;
    position: absolute;
    height: 200px;
    top: 5%;
    z-index: 100;

    .sec1{
      font-size:5rem;
      font-weight:bold;
      color:#FFFFFF;
      font-style: italic;
    }

    .sec2{
      color:#FFFFFF;
      font-size:3rem;

    }
    .sec3{
      font-size:3rem;
      width:100px;
      paddgin:5px;
      background:#ae121c;
      color:#000000;
      font-weight:bold;
      float:right;
    }

    .sec4{
      color: #FFFFFF;
      border: 5px solid #FFFFFF;
      padding: 3px;
      float: right;
      width: 191px;
      margin-top: 10px;
      text-align: center;
      font-size: 2rem;
    }
  }

  .slide4 {

    color:#FFFFFF;
    width: 100%;
    position: absolute;
    height: 200px;
    top: 5%;
    z-index: 100;

    .sec1{
      font-size: 6rem;
      font-weight: 200;
    }

    .sec2{
      font-size: 6rem;
      font-weight: bold;
      font-style: italic;
    }

    .sec3{
      font-size: 2rem;
      font-weight: 200;
    }


    .sec4{
      color: #FFFFFF;
      border: 5px solid #FFFFFF;
      padding: 3px;
      float: right;
      width: 191px;
      margin-top: 10px;
      text-align: center;
      font-size: 2rem;
    }
  }

  .slide5 {
    width: 80%;
    position: absolute;
    height: 200px;
    top: 40%;
    z-index: 100;
    text-align: left;
    left: 16%;

    .sec2{
      color: #FFF;
      font-size: 3rem;
      text-align: left;
    }

    .sec3{
      color:#FEFEFE;
    }
  }

//}
.plus{
  color:#ae121c;
}

.btn_pack{
  color: #FFFFFF;
  padding: 3px;
  float: right;
  width: 191px;
  margin-top: 10px;
  text-align: center;
  font-size: 2rem;
  background:#af121c;
}

.osez{
  color:#000000;
  font-size: 1.8rem;
  h3 {
    font-size: 8rem;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 53px;
  }

  .icons{
    margin-left: 75%;
    margin-top: 30px;
  }
  .div_icon{
    width:66px;
    float:left;

    img{
      width:62px;
    }
  }
}

.link_slider{
  color:#FFFFFF;
}

.link_slider:hover{
  color:#FFFFFF;
  text-decoration:none;
}

.langue{

}