$color_text_product : #FFFFFF;


.product_slider1{

  background:url('/img/produits/slider.jpg');
  background-size:100% auto;
  background-repeat:no-repeat;
  min-height:550px;
  padding-top:7%;

  h1{
    color:#FFFFFF;
    font-weight:bold;
    font-size:4rem;
  }

  h2{
    color:#FFFFFF;
  }

  .price{
    color:#9e9e9e;
    font-size: 8rem;
    font-weight: 100;
    background: url("/img/garantie-1-an.png");
    background-repeat: no-repeat;
    background-size: 100px auto;
    background-position: 260px 38px;


  }
  .btn_pack{
    float:left;
    padding:0px;
    margin:0px;
    width:auto;
    input{
      margin: 0px;
    }
  }

}

.no_padding{
  padding:0px;
}

.fullwidth{
  width:100%;
}
.after_slide{
  margin-top:30px;
  background:url('/img/produits/principal_product2.png');
  background-size:100% auto;
  background-repeat:no-repeat;
  min-height:500px;

  .citation{
    background:rgba(0,0,0,0.8);
    padding:10px;
    color:#FFFFFF;
    margin-top:100px;

    .author{
      font-size:1rem;
      font-weight:100;
    }
  }
}
.principal_product{
  background:#FFFFFF;
  margin-bottom:100px;

}
.product_box h3{
  color: $color_text_product;
  font-size:1.5rem;
  min-height:48px;
  a{
    color:#FFFFFF;
  }

}


.caracteristiques{
  color:#6b6b6b;
  margin-top: 50px;
  border: 1px solid #d0d0d0;


  th{
    background:#eeeeee;
    padding: 10px;
    font-size: 3rem;
    font-weight: 400;

  }

  td{
    font-weight: 100;
    font-size: 1.5rem;
  }

  .souligne{
    border-bottom:1px solid #d0d0d0
  }

  .gras{
    font-weight: 500;
    font-size: 2rem;
    padding: 10px;
  }
}

.product_accordion {
  margin-bottom:10px;
}

.href{
  background:#870c14;
  padding:10px;
  display:block;
  color:#FFFFFF;
  text-transform: uppercase;
  font-size:2.5rem;
  font-weight:bold;
}
.href:hover, .href:focus{
  color:#9e9e9e;
  text-decoration:none;
}


.card {
  position: relative;
  display: block;
  margin-bottom: .75rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: .25rem;
  min-height:360px;
  max-width:20rem;
  text-align:center;
  margin-bottom: 2rem;
  min-height: 395px;

}

.bg_white{
  background:#FFFFFF;
}

.card-title {
  margin-bottom: .75rem;
  font-size:1.5rem;
  text-align:left;
  font-weight: bold;
  color:#000000;
}

.card-text{
  font-size:1rem;
}

.card-img-top {
  border-radius: .25rem .25rem 0 0;
}
.card-block {
  padding: 1.25rem;
  color:#000000;
}

.product_box{
  min-height:300px;



  .in_product_box{
    border:1px solid #656565;
    padding:10px;
    width:100%;
    margin-bottom: 12%;
    min-height:330px;
  }
}

.product_box .red{
  font-size:1rem;
  display:block;
  min-height:20px;
}

.product_box img{
  max-width:80%;
  /*border:1px solid #656565;*/
  padding:10px;
}

.product_details h1, .price{
  color: $color_text_product;
}



.slides_product img
{
  max-width:100%;
  width:auto;
}


.arrow {
  font-size: 9px;
  color: $color_text_product;
  position: absolute;
  right: 8px;
  top: 15px;
  cursor: default;
  line-height: 1px;
}

.parent {
  position: relative;
  width: 100%;
  height: 20px;
  float: left;
  margin: 0px;
  padding: 0px;
}

.qty{
  background :#000;
  padding:5px;
  width:100%;
  color: $color_text_product;
}

.price_display
{
  font-weight:bold;
  font-size:2rem;
}

.instead_of{
  font-size: 1rem;
}

.add_to_cart{
  background:$a_menu_hover_bg;
  color: $color_text_product;
 /* border-radius:10px;*/
  border:0px;
  padding:10px;
  margin-top:10px;
}

.show_product{
  min-height:80%;
}

.show_product2{
  color: $color_text_product;
}

.show_product2 div:nth-child(1){

  margin-top:50px;
  border : 1px solid #656565;
  border-radius : 20px;
  padding:10px;
  font-size: 1.3rem;
}

.show_product2 div:nth-child(2){
  margin-top:15px;
  font-size: 1.3rem;
}

.second_div{
  border : 1px solid #656565;
  border-radius : 20px;
  padding:10px;
}

.show_product2 div:nth-child(1) ul{
  list-style:none;

}

.second_div ul{
  width:50%;
  float:left;
  list-style:none;
}

.img_prd{
  position: absolute;
  margin-left: 70%;
  margin-top: -44px;
}
.arrow-up {


  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #414141;
  position: absolute;
  z-index: 20;
  right: 92px;
  top: -5px;
}


.content_panier{
  padding: 10px;
  position: absolute;
  right: 1%;
  top: 43px;
  z-index: 10;
  background: linear-gradient(to bottom, #414141, #1c1d1d);
  background: -prefixe-linear-gradient(top, #414141, #1c1d1d);
  margin-top: 4px;
  min-width: 200px;
  display:none;
  min-height: 100px;
  color:#FFF;
  border-radius:10px;
}

.title_panier{
  color:#aeadad;
  padding-bottom:10px;
}

.btn-red{
  background:$a_menu_hover_bg;
  color:#FFF;
  padding:10px;
  display: block;
}

.sub_total{
  text-align:right;
}

.content_panier hr{
  height:1px;
  margin-top:0px;
  margin-bottom:0px;
  border:1px solid #3d3c3b;
}

.item_div{
  min-height:100px;
}

#cross {
  width: 10px;
  height: 10px;
  position: relative;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  float:right;
  cursor:pointer;
}

#cross:before, #cross:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: #8E8E8E;
}

#cross:before {
  left: 50%;
  width: 30%;
  margin-left: -15%;
  height: 100%;
}

#cross:after {
  top: 50%;
  height: 30%;
  margin-top: -15%;
  width: 100%;
}

blockquote {
  padding: 6px 16px;
  border: none;
  quotes: "\201C" "\201D";
  display: inline-block;
  position: relative;
  line-height:1;
  width: 100%;
  text-align: center;
  letter-spacing: 4px;
}

blockquote:before {
  content: open-quote;
  font-weight: bold;
  position:absolute;
  top:0;
  left: 0;
}
blockquote:after {
  content: close-quote;
  font-weight: bold;
  position:absolute;
  bottom:0; right: 0;
}

.video{
  margin-top:20px;
  color:#000000;
  font-size:3rem;
}