body{
  background:#FFFFFF;

}
html, body {
  height: 100%;
}
.wrapper {
  min-height: 100%;
  height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto -195px; /* the bottom margin is the negative value of the footer's height */
}

.in_parallax{
  color:#FFFFFF;
}

.home_h1 {
  h1 {
    color: #FFFFFF;
    font-size: 4rem;
    margin: 0px;
  }
  span{
    font-size:3rem;
  }
}


.footer{
  background:url('/img/fond_footer.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 195px;
  padding-top: 17px;

}
.bloc_home_footer{
  margin-left: 5.7%;
}

.bloc_home_footer div
{
  border:1px solid $a_font_color;
  border-radius:20px;
  padding:10px;
  min-height:160px;
  color:#FFF;
  text-transform:uppercase;
  font-weight: bold;
  text-align: right;
}

.bloc_home_footer div:nth-child(2){
  text-align: center;
}

.bloc_home_footer div:nth-child(3){
  text-align: left;
}

.camera_home
{
  float:left;
}

.boite_home{
  position: absolute;
  right: -35px;
  top:45px;
}
.lecture_home {
  margin: 0 auto;
  display: block;
}

/* jssor slider bullet navigator skin 05 css */
/*
.jssorb05 div           (normal)
.jssorb05 div:hover     (normal mouseover)
.jssorb05 .av           (active)
.jssorb05 .av:hover     (active mouseover)
.jssorb05 .dn           (mousedown)
*/
.jssorb05 {
  position: absolute;
}
.jssorb05 div, .jssorb05 div:hover, .jssorb05 .av {
  position: absolute;
  /* size of bullet elment */
  width: 16px;
  height: 16px;
  background: url('/img/b05.png') no-repeat;
  overflow: hidden;
  cursor: pointer;
}
.jssorb05 div { background-position: -7px -7px; }
.jssorb05 div:hover, .jssorb05 .av:hover { background-position: -37px -7px; }
.jssorb05 .av { background-position: -67px -7px; }
.jssorb05 .dn, .jssorb05 .dn:hover { background-position: -97px -7px; }

/* jssor slider arrow navigator skin 22 css */
/*
.jssora22l                  (normal)
.jssora22r                  (normal)
.jssora22l:hover            (normal mouseover)
.jssora22r:hover            (normal mouseover)
.jssora22l.jssora22ldn      (mousedown)
.jssora22r.jssora22rdn      (mousedown)
*/
.jssora22l, .jssora22r {
  display: block;
  position: absolute;
  /* size of arrow element */
  width: 40px;
  height: 58px;
  cursor: pointer;
  background: url('/img/a22.png') center center no-repeat;
  overflow: hidden;
}
.jssora22l { background-position: -10px -31px; }
.jssora22r { background-position: -70px -31px; }
.jssora22l:hover { background-position: -130px -31px; }
.jssora22r:hover { background-position: -190px -31px; }
.jssora22l.jssora22ldn { background-position: -250px -31px; }
.jssora22r.jssora22rdn { background-position: -310px -31px; }

.icon_social{
  font-size:4rem;
  margin-left:16%;

}
.container-fluid{
  background: red; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(circle, #111010, #090909, #000000); /* Safari */
  background: -o-radial-gradient(circle, #111010, #090909,  #000000); /* Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(circle, #111010, #090909,  #000000); /* Firefox 3.6 to 15 */
  background: radial-gradient(circle, #111010, #090909,  #000000); /* Standard syntax */
}
.second_menu{
  background:#141414;
  padding:0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.collapse{
  padding:0px;
}
.under_picture{
  margin-top:-40px;
  color:$a_menu_hover_bg;
  text-align:center;
  font-size:2rem;
}
.rond_accueil{
    background:#FFFFFF;
    border-radius:50%;
    width:80px;
  margin: 0 auto;
    height:80px;
    border:1px solid $a_menu_hover_bg;
  line-height:80px;
  text-align:center;
  color:$a_menu_hover_bg;
  font-size:3rem;
}
.no_padding{
  padding:0px;

}
.corps_home{
  background:#FFFFFF;
min-height:555px;
  .row{
    margin-left:0px;
    margin-right:0px;
  }

  .img {

  }

  .content
  {
    img{
      max-width:100%;
    }
  }

  .in_home{
    margin-top:10px;
    border:1px solid #f2f2f2;
    width:256px;
    min-height: 350px;
    margin-bottom: 20px;
  color:#434343;

    .hashtag:before{
      content:"#";
      color:#af121c;
      font-weight:bold;
      font-size:1.5rem;
    }

    .hashtag{
      font-weight:bold;
      color:#000000;
      font-style:normal;
      font-size:1.5rem;
    }

    .in_in_home{
      padding:10px;
      font-size:1rem;
    }

    .follow{
      color:#af121c;
      font-weight:bold;
    }
  }
}

.down{
  background-image: url('/img/down_grey.png');
  width: 48px;
  height: 48px;
  position: absolute;
  margin-top: -104px;
  left: 46.9%;
  z-index: 1111;

}