
@font-face {
  font-family: MyriadPro-regular;
  src: url("/fonts/MyriadPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: MyriadPro-bold;
  font-weight: bold;
  src: url("/fonts/MyriadPro-Bold.otf") format("opentype");
}

body {
  font-family: 'Oswald', sans-serif;
}
h1, h2, h3, h4, h5, h6{
  color:#FFFFFF;
}

.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand{
  margin-left:20px;
}

.navbar
{
  margin-bottom:0px;
}
.main_container{
  max-width:80%;
  margin:0 auto;
}

.navbar-default{

  font-family: 'Oswald', sans-serif;
  background:#0a0a0a;
  border:0px;
}

.navbar_compte_margin_right{
  margin-right:45px;
}
.red{
  color:red;
  font-weight:bold;
  font-size: 23px;

}
.navbar_cart{

  border-left:1px solid $a_font_color;
  margin-top:10px;
  padding-left:20px;
  cursor:pointer;
}

.navbar_cart img{
  margin-top: -11px;
}

.nav_2{
  background: #141414 !important;
}
.nav_2>li
{
  width:20%;
  float:left;
  text-align:center;
  border-right:1px solid #9a9a9a;
}
.nav_2>li:last-child{
  border-right:0px;
}

.nav_2>li a{
  color:#9a9a9a;
  background:#141414;
  font-weight:bold;
  font-size:1rem;

}

.nav_2>li .actif
{
  color:  #8f0b14;
  display: block;

}

.nav > li > a:hover, .nav > li > a:focus{
  color:  #8f0b14;
  display: block;
  background:#141414;

}

.hvr-shutter-in-horizontal:before{
  background:#000;
}

.actif:before{
 /* background:$a_menu_hover_bg;*/
}


#chargement{
  position:absolute;
  bottom:10px;
  right:10px;
  width:200px;
  height:100px;
  padding:10px;
  border-radius:10px;
  display: none;
  background: #F1F1F1;
  -moz-box-shadow: -0.5em -0.5em 0.4em #3A3A37;
  -webkit-box-shadow: -0.5em -0.5em 0.4em #3A3A37;
  box-shadow: -0.5em -0.5em 0.4em #3A3A37;

}

.hr{

  height: 1px;
  border: 0px;
  border-bottom: 1px solid #1F1F1F;


}
.suivez_nous{
  background:url('/img/fond_suivez_nous.png');
  background-repeat:no-repeat;
  height :180px;
  width:253px;
}

.circle
{
  display: inline-block;
  border-radius: 160px;
  box-shadow: 0px 0px 2px #fff;
  color: #3D0205;
  height: 60px;
  width: 60px;
  font-size: 3rem;
  padding-top: 10px;
  margin-left: 10px;
  background: #FFF;
  margin-top: 60px;
}


footer{
  margin-top:30px;
  background:#000000;
  padding:30px;
}

.link_slider2{
  color:#000000;
}

.slider_product{
  max-height:400px
}

.title_modal{
  color:#000000;
}

.modal-content{
  border-radius:0px;
}
.modal-header  {
  .close {
    font-size: 35px;
  }
}

#newsletter {
  .modal-dialog {
    /* new custom width */
    width: 40%;
    background: #af121c;
    color: #FFFFFF;
    margin-top: 7%;


  }

  .modal-content{
    background: #af121c;
    color:#FFFFFF;
  }

  .line{
    margin-bottom:10px;
  }

  .first-m6{
    border-right:1px solid #e5e5e5;
    margin-bottom:20px;
  }

  .petit{
    font-size:smaller;
  }
 .white{
    color:#FFFFFF;
  }
}

.form{
  margin-bottom:10px;
}
.p_contact{
  color:#FFFFFF
}