$color_panier: #807f7f;
$border_panier: #1F1F1F;

.panier{
  color:$color_panier;
}
.panier_step1 th{
  background:#111110;
  color:$color_panier;
  padding:10px;
}

.panier_step1 td{
  color:#aeadad;
}

.panier_step1 td input {
  background: #000;
  border: 1px solid $border_panier;
  width:50px;
  padding:5px;
  text-align:center;
}

.input_teetan{
  background: #000;
  border: 1px solid $border_panier;
  padding:5px;
}

.codepromo{
  margin-top:50px;
}

.action_cart{
  font-size:12px;
  cursor:pointer;
}

.panier_step1>tbody>tr:last-child {
  border-bottom:1px solid $border_panier;
}

.codepromo{
  font-size:1.5rem;
  font-weight:bold;
}

.submit_code_promo{
  text-align:right;
  width:100%;

  background: #000;
  border: 0;
  margin-top:10px;
}

.step_button{
  font-weight:normal;
  width:100%;
}

.continue_buy{
  font-weight:normal;
  color:$color_panier;
}

.continue_buy a {
  color:$color_panier;
  font-size:12px;
}

/**

Step 2

*/

.cart_step_2
{
  a{

    color:$color_panier;
  }
  .modal-footer{
    border-top:0px;
    text-align:center;
  }

  .loginSubmit{
     background:red;
      border:1px solid  red;
  }

  .loginSubmit:after{
    content:" > ";
  }

  .white{
    color:#FFF;
  }

  .commander, .retour{
    display: block;
    float:left;
    width:50%;
  }

  .commander:after{
    content:" >> ";
  }

  .retour:before{
    content:" << ";
  }


}

.form-horizontal .form-group{
  margin-left:0px;
  margin-right:0px;
}

.cart_step_3{
  .border {
    border: 3px solid #B11616;
    padding:15px;
  }

  .cache_form_paiement{
    height: 180px;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 50px;
  }
  .form_adress{



    input{
      background:#000;
      border:0px;
      border-bottom:1px solid $border_panier;
      color:#FFF;
    }

    .submit{
      background:$border_panier;
      float:right;
    }

    input:focus{
      outline:0px;
    }

    label{
      float: left;
      width: 80%;
      font-weight: normal;
      color: #FFF;
    }

    .check{
      width:10%;
      float:left;
    }

    .multi_facturation{
      display:none;
    }
  }

  h4{
    color:#FFF;
    font-size:15px;
  }

  .panier_step3 {

    img{
      border:1px solid $border_panier;
      margin-right:10px;
    }

    tr{
      border-bottom:1px solid $border_panier;

    }
    tr td{
      padding:10px;
      font-size:12px;
    }

    tr td div:first-child, tr td div::nth-child(3){
      padding-left: 0px;
      text-align:right;
    }



  }
}


.breadcrumb2 {
  /*centering*/
  display: inline-block;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  border-radius: 5px;
  /*Lets add the numbers for each link using CSS counters. flag is the name of the counter. to be defined using counter-reset in the parent element of the links*/
  counter-reset: flag;
  width:100%;
}

.breadcrumb2 a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  color: white;
  /*need more margin on the left of links to accomodate the numbers*/
  padding: 0 10px 0 60px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;
}
/*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
.breadcrumb2 a:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
}
.breadcrumb2 a:first-child:before {
  left: 14px;
}
.breadcrumb2 a:last-child {
  border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
  padding-right: 20px;
}

/*hover/active styles*/
.breadcrumb2 a.active, .breadcrumb2 a:hover{
  background: #333;
  background: linear-gradient(#333, #000);
}
.breadcrumb2 a.active:after, .breadcrumb2 a:hover:after {
  background: #333;
  background: linear-gradient(135deg, #333, #000);
}

/*adding the arrows for the breadcrumb2s using rotated pseudo elements*/
.breadcrumb2 a:after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px; /*half of square's length*/
  /*same dimension as the line-height of .breadcrumb2 a */
  width: 36px;
  height: 36px;
  /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's:
  length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
  if diagonal required = 1; length = 1/1.414 = 0.707*/
  transform: scale(0.707) rotate(45deg);
  /*we need to prevent the arrows from getting buried under the next link*/
  z-index: 1;
  /*background same as links but the gradient will be rotated to compensate with the transform applied*/
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  /*stylish arrow design using box shadow*/
  box-shadow:
  2px -2px 0 2px rgba(0, 0, 0, 0.4),
  3px -3px 0 2px rgba(255, 255, 255, 0.1);
  /*
      5px - for rounded arrows and
      50px - to prevent hover glitches on the border created using shadows*/
  border-radius: 0 5px 0 50px;
}
/*we dont need an arrow after the last link*/
.breadcrumb2 a:last-child:after {
  content: none;
}
/*we will use the :before element to show numbers*/
.breadcrumb2 a:before {
  content: counter(flag);
  counter-increment: flag;
  /*some styles now*/
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  background: #444;
  background: linear-gradient(#444, #222);
  font-weight: bold;
  padding-left: 7px;
}


.flat a, .flat a:after {
  background: white;
  color: black;
  transition: all 0.5s;
  width:20%;
}
.flat a:before {
  background: white;
  box-shadow: 0 0 0 1px #ccc;
}
.flat a:hover, .flat a.active,
.flat a:hover:after, .flat a.active:after{
  background: #9EEB62;
}


.cart_step_4{
  font-weight: 100;
  font-size: 2rem;
}


.or-spacer {
  margin-top:100px; margin-left:100px; width:400px;
  position:relative;

  .mask {
    overflow:hidden; height:20px;
    &:after {
      content:'';
      display:block; margin:-25px auto 0;
      width:100%; height:25px;
      border-radius:125px / 12px;
      box-shadow:0 0 8px black;
    }
  }
  span {
    $size:50px;
    width:$size; height:$size;
    position:absolute;
    bottom:100%; margin-bottom:-$size/2;
    left:50%; margin-left:-$size/2;
    border-radius:100%;
    box-shadow:0 2px 4px #999;
    background:white;
  }
  span i {
    $offset:4px;
    position:absolute;
    top:$offset; bottom:$offset;
    left:$offset; right:$offset;
    border-radius:100%;
    border:1px dashed #aaa;

    text-align:center;
    line-height:40px;
    font-style:normal;
    color:#999;
  }
}


.bord{
  border-right:1px solid #FFFFFF;
  min-height:300px;
}

.or{
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 20px;
  position: absolute;
  background-color: #FEFEFE;
  color: #777777;
  text-align: center;
  font-size: 1.5rem;
  right: -20px;
  top: 43%;
}