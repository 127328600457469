.list_partner{
  min-height:225px
}
.list_partner h4{
  color:#FFF;
  text-transform:uppercase;
  margin-bottom: 0;
  margin-top:5px;
}

.list_partner h5{
  color:red;
  font-size:small;
  margin-top: 0;
  margin-bottom: 0;
}

.list_partner img{
  border : 2px solid red;
}

.showPartners{

  color:#FFFFFF;
  font-family: 'Oswald', sans-serif;
}
.showPartners img{
  max-width:100%;
}

.showPartners strong{
  color:#FFF;
}

.showPartners .imgAlignLeft{
  float:left;
  padding-right:10px;
  padding-bottom:10px;
}

.col-droite img{
  margin-bottom : 20px;
}

.citation{
  color:#a0a0a0;
  font-family: 'Oswald', sans-serif;
  padding:5px;
  height: 125px;
  /*background-image:url('/img/bg_citation.png');*/
  background-repeat:no-repeat;
  background-size:100% 100%;
  font-size:2rem;
}

.citation div:nth-child(1) img, .citation div:nth-child(4) img{
  margin-bottom:0px;
}
.citation div:nth-child(1){
  font-size:4rem;
  font-weight:bold;
}

.citation div:nth-child(2) {
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 33px;
  margin-top: -9px;
}

.citation div:nth-child(2) span{
  font-size:2rem;
  text-align:right;
}

.citation div:nth-child(3){
  font-size:1.4rem;
  text-align:right;
  padding-right: 0;
}


.citation div:nth-child(4){
  font-size: 3rem;
  text-align: right;
  font-weight:bold;
}

.partenaire{


  color:#FFFFFF;
}

.breadcrumb{
  background:#141414;
}